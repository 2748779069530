exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-cookies-tsx": () => import("./../../../src/pages/cookies.tsx" /* webpackChunkName: "component---src-pages-cookies-tsx" */),
  "component---src-pages-forgetpwd-tsx": () => import("./../../../src/pages/forgetpwd.tsx" /* webpackChunkName: "component---src-pages-forgetpwd-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-login-tsx": () => import("./../../../src/pages/login.tsx" /* webpackChunkName: "component---src-pages-login-tsx" */),
  "component---src-pages-logout-tsx": () => import("./../../../src/pages/logout.tsx" /* webpackChunkName: "component---src-pages-logout-tsx" */),
  "component---src-pages-pricing-change-index-tsx": () => import("./../../../src/pages/pricing/change/index.tsx" /* webpackChunkName: "component---src-pages-pricing-change-index-tsx" */),
  "component---src-pages-pricing-index-tsx": () => import("./../../../src/pages/pricing/index.tsx" /* webpackChunkName: "component---src-pages-pricing-index-tsx" */),
  "component---src-pages-pricing-payment-tsx": () => import("./../../../src/pages/pricing/payment.tsx" /* webpackChunkName: "component---src-pages-pricing-payment-tsx" */),
  "component---src-pages-privacypolicy-tsx": () => import("./../../../src/pages/privacypolicy.tsx" /* webpackChunkName: "component---src-pages-privacypolicy-tsx" */),
  "component---src-pages-profile-changepwd-tsx": () => import("./../../../src/pages/profile/changepwd.tsx" /* webpackChunkName: "component---src-pages-profile-changepwd-tsx" */),
  "component---src-pages-profile-index-tsx": () => import("./../../../src/pages/profile/index.tsx" /* webpackChunkName: "component---src-pages-profile-index-tsx" */),
  "component---src-pages-profile-purchase-tsx": () => import("./../../../src/pages/profile/purchase.tsx" /* webpackChunkName: "component---src-pages-profile-purchase-tsx" */),
  "component---src-pages-purchase-changesuccess-tsx": () => import("./../../../src/pages/purchase/changesuccess.tsx" /* webpackChunkName: "component---src-pages-purchase-changesuccess-tsx" */),
  "component---src-pages-purchase-error-tsx": () => import("./../../../src/pages/purchase/error.tsx" /* webpackChunkName: "component---src-pages-purchase-error-tsx" */),
  "component---src-pages-purchase-index-tsx": () => import("./../../../src/pages/purchase/index.tsx" /* webpackChunkName: "component---src-pages-purchase-index-tsx" */),
  "component---src-pages-purchase-payment-tsx": () => import("./../../../src/pages/purchase/payment.tsx" /* webpackChunkName: "component---src-pages-purchase-payment-tsx" */),
  "component---src-pages-purchase-success-tsx": () => import("./../../../src/pages/purchase/success.tsx" /* webpackChunkName: "component---src-pages-purchase-success-tsx" */),
  "component---src-pages-signup-tsx": () => import("./../../../src/pages/signup.tsx" /* webpackChunkName: "component---src-pages-signup-tsx" */),
  "component---src-pages-unsubscribe-index-tsx": () => import("./../../../src/pages/unsubscribe/index.tsx" /* webpackChunkName: "component---src-pages-unsubscribe-index-tsx" */),
  "component---src-pages-unsubscribe-success-tsx": () => import("./../../../src/pages/unsubscribe/success.tsx" /* webpackChunkName: "component---src-pages-unsubscribe-success-tsx" */)
}

